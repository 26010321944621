<template>
  <div>
    <div class="title-wrapper">
      <h2>
        <span class="icon">
          <img :src="require(`@/assets/images/icon/${icon}`)" :alt="title" />
        </span>
        {{ title }}
      </h2>
      <span><div @click="handleShowAll()" class="show-all">全て表示</div></span>
    </div>

    <slot name="afterHeader"></slot>

    <div class="mid-category-slide" v-if="listData.length">
      <VueSlickCarousel v-bind="settingSlide">
        <router-link
          v-for="(item, index) in listData" :key="item.id + index"
          :to="{ name: 'AdvertisingDetail', params: { id: item.id || 1 } }"
          class="advertise-link"
        >
          <figure class="advertise-image">
            <img :src="(item.file ? item.file.path : '') | imageEmpty" alt="" />
          </figure>
          <h5>
            <template v-if="item.is_campaign === 1 && checkDateBetween(item.campaign_start_datetime, item.campaign_end_datetime)">
              <template v-if="item.point_type === 2">{{ item.campaign_pointback_rate | numberWithCommas(',')  }}<span>%</span></template>
              <template v-else>{{ item.campaign_pointback_amount | numberWithCommas(',', '') }}</template>
            </template>
            <template v-else>
              <template v-if="item.point_type === 2">{{ item.normal_pointback_rate | numberWithCommas(',')  }}<span>%</span></template>
              <template v-else>{{ item.normal_pointback_amount | numberWithCommas(',', '') }}</template>
            </template>
            <span>ポイント</span>
          </h5>
        </router-link>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import { mapState } from 'vuex'
import { get } from 'lodash-es'

import Common from '@/mixins/common.mixin'
import { getListByMid } from '@/services/mid-category.service'
import { getListAdvertises } from '@/services/advertises.service'

export default {
  name: 'MidCategoryCarousel',

  components: {
    VueSlickCarousel
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    categoryId: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    isAllCategory: {
      type: Boolean,
      default: false
    }
  },
  mixins: [Common],
  computed: {
    ...mapState('auth', ['token'])
  },
  data () {
    return {
      settingSlide: {
        autoplay: false,
        infinite: true,
        dots: false,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 500,
        swipeToSlide: true
      },
      listData: [],
      hostName: process.env.VUE_APP_USER_DOMAIN
    }
  },

  async mounted () {
    const params = {
      perPage: 10, // limit: 10
      page: 1,
      orderBy: 'publish_start_datetime',
      sortedBy: 'desc'
    }
    const res = this.isAllCategory ? await getListAdvertises(params) : await getListByMid(this.categoryId, params)
    const midData = get(res, 'data.data', [])
    this.listData = midData
  },

  methods: {
    handleShowAll () {
      const typeColor = '1' // TODO: check typeColor
      const level = this.isAllCategory ? 'new' : 'mid'
      const categoryId = this.isAllCategory ? 'all' : this.categoryId

      if (this.checkWebview()) {
        const matchValue = {
          id: categoryId,
          '#color': typeColor,
          '#levelCategory': level
        }
        const url = process.env.VUE_APP_CATEGORY_DOMAIN.replace(
          /id|#color|#levelCategory/gi,
          function (key) {
            return matchValue[key]
          }
        )
        this.triggerEventApp('OPEN_WEBVIEW', { url: url })
      } else {
        this.$router.push({
          name: 'CategoryList.Category',
          params: { id: categoryId, title: !categoryId ? this.title : '' },
          query: { typeColor, level }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$sp: 780px;
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

$viewport: 780;
@function pv($num, $width: $viewport) {
  @return calc(1vw * $num * 100 / $width);
}

.mid-category-slide {
  width: 100%;
  margin-bottom: 60px;
  max-width: 840px;

  @media #{$info-tablet-horizontal} {
    max-width: calc(100vw - 380px);
  }

  @media #{$info-phone} {
    max-width: 100vw;
    margin-bottom: 8vw;
  }
}

.title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto 20px;

  @media #{$info-phone} {
    margin: 0 auto 2vw;
  }

  h2 {
    font-family: A1 Gothic M, "A1 Gothic M", "Noto Sans JP", sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media #{$info-phone} {
      font-size: pv(28);
    }
  }

  .icon {
    width: 40px;
    text-align: center;
    img {
      max-height: 35px;
      margin-right: 20px;
    }

    @media #{$info-phone} {
      width: 5vw;

      img {
        max-height: 4vw;
        margin-right: 2vw;
      }
    }
  }

  span {
    font-size: 20px;
    font-weight: 400;
    line-height: 1;

    @media #{$info-phone} {
      font-size: pv(22);
    }

    .show-all {
      font-family: A1 Gothic M, "A1 Gothic M", "Noto Sans JP", sans-serif;
      text-decoration: underline;
      color: #282828;
      cursor: pointer;
    }
  }
}

.mid-category-slide {
  .advertise-image {
    height: 225px;

    @media #{$info-tablet-horizontal} {
      height: calc((100vw - 485px) / 3);
    }
    @media #{$info-phone} {
      height: 24.77vw;
    }
    @media only screen and (max-width : 530px) {
      height: 23.77vw;
    }
    @media only screen and (max-width : 430px) {
      height: 22.77vw;
    }
    @media #{$info-phone-4} {
      height: 21.77vw;
    }
    @media only screen and (max-width : 260px) {
      height: 20.77vw;
    }
  }

  img {
    max-width: 263px;
    object-fit: contain;
    width: 100%;
    height: 100%;

    @media #{$info-tablet-horizontal} {
      max-width: calc((100vw - 400px) / 3);
    }
    @media #{$info-phone} {
      max-width: 29vw;
      border-radius: 0.8vw;
    }
    @media only screen and (max-width : 530px) {
      max-width: 28vw;
    }
    @media only screen and (max-width : 430px) {
      max-width: 27vw;
    }
    @media #{$info-phone-4} {
      max-width: 26vw;
    }
    @media only screen and (max-width : 260px) {
      max-width: 25vw;
    }
  }

  h5 {
    font-family: A1 Gothic M, "A1 Gothic M", "Noto Sans JP", sans-serif;
    font-size: 32px;
    font-weight: 500;
    position: relative;
    display: inline-block;
    margin: 10px 0;
    color: #282828;

    span {
      font-size: 16px;
      margin-left: 5px;
    }

    @media #{$info-tablet-horizontal} {
      font-size: pv(32, 1435);
      margin: 1vw 0;

      span {
        font-size: pv(22, 1435);
        margin-left: 1vw;
      }
    }
    @media #{$info-phone} {
      font-size: pv(32);
      margin: 1vw 0;

      span {
        font-size: pv(22);
        margin-left: 1vw;
      }
    }
  }

  h5:before {
    content: "";
    background-image: url("../../assets/images/icon/point_icon.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: -30px;
    transform: translateY(-50%);

    @media #{$info-tablet-horizontal} {
      width: 2vw;
      height: 2vw;
      left: -2.5vw;
    }
    @media #{$info-phone} {
      width: 3vw;
      height: 3vw;
      left: -4vw;
    }
  }

  .advertise-link {
    margin: 0 auto;
  }
}
</style>
