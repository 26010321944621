<template>
  <div class="wrap-banner slide-banner-top" v-if="listData.length">
    <VueSlickCarousel v-bind="settingSlide" class="main-slide" @init="setArrowPosition" @reInit="reInit">
      <div class="banner-item" v-for="item in listData" :key="item.id">
        <a @click="handleRedirectUrl(item.redirect_url)">
          <picture>
            <source media="(min-width:781px)" :srcset="item.file.path">
            <source media="(max-width:780px)" :srcset="item.thumbnail_sp.path">
            <img :src="item.file.path" alt="" class="switching">
          </picture>
        </a>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import { mapState } from 'vuex'

import { PAGE_NATIVE } from '@/enum/title-header'
import Common from '@/mixins/common.mixin'
import Dimensions from '@/mixins/dimensions.mixin'

export default {
  name: 'SlideBanner',

  components: {
    VueSlickCarousel
  },

  props: {
    listData: {
      type: Array,
      default: () => []
    }
  },

  mixins: [Common, Dimensions],

  computed: {
    ...mapState('auth', ['token'])
  },

  data () {
    return {
      settingSlide: {
        autoplay: false,
        infinite: true,
        dots: true,
        arrows: true,
        slidesToShow: 1,
        swipeToSlide: true,
        speed: 500,
        responsive: [{
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            centerMode: true,
            centerPadding: '0'
          }
        }]
      },
      hostName: process.env.VUE_APP_USER_DOMAIN,
      arrowPositionFlag: false
    }
  },

  methods: {
    checkIsCategoryList (url) {
      const routeCateList = ['category', 'survey', 'receipt']
      const isCateList = !!routeCateList.find(route => url.includes(route))
      return isCateList
    },
    handleRedirectUrl (url) {
      //  CHECK URL INTERNAL OR EXTERNAL
      const parseUrl = new URL(url)
      // REDIRECT PAGE NATIVE
      let flagPageNative = false
      PAGE_NATIVE.forEach((elm) => {
        if (elm.path === parseUrl.pathname) {
          flagPageNative = true
        }
      })

      if (this.hostName.includes(parseUrl.origin)) {
        if (flagPageNative) {
          const namePageNative = PAGE_NATIVE.find(x => x.path === parseUrl.pathname).name
          // CHECK URL IS LINK TO LOGIN OR REGISTER
          if (this.token &&
            (namePageNative === 'Login' || namePageNative === 'Register')) {
            this.openPageNative('Index')
          } else if (!this.token && namePageNative !== 'Index' && namePageNative !== 'Register') {
            this.openPageNative('Login')
          } else this.openPageNative(namePageNative)
        } else {
          if (!this.checkWebview()) {
            if (url.includes('/lp/')) {
              window.open(url, '_self')
            } else if (url.includes('/cp/')) {
              window.open(url, '_blank')
            } else if (this.checkIsCategoryList(url)) {
              window.open(url, '_self')
            } else this.$router.push({ path: parseUrl.pathname })
          } else {
            // IF URL INTERNAL OPEN IN WEBVIEW
            if (this.checkWebview()) {
              const routeTriggerEventApp = ['feature-list', 'receipt', 'survey']
              let routeName = routeTriggerEventApp.find(route => url.includes(route))
              const isTriggerEventApp = !!routeName
              if (isTriggerEventApp) {
                if (routeName === 'feature-list') {
                  routeName = 'feature'
                }
                const nameEvent = `PAGE_REDIRECT_${routeName.toUpperCase()}`
                this.triggerEventApp(nameEvent, {})
              } else {
                this.triggerEventApp(
                  'OPEN_WEBVIEW',
                  { url: url }
                )
              }
            }
          }
        }
      } else this.openUrl(url)
      // if (this.checkWebview()) {
      //   this.triggerEventApp(
      //     'OPEN_BROWSER',
      //     { url: url }
      //   )
      // } else window.open(url, '_blank')
    },
    setArrowPosition () {
      if (this.window.width >= 768) return

      const bannerEl = document.querySelector('.slide-banner-top')
      if (!bannerEl || !this.listData.length) return

      const imgEl = bannerEl.querySelector('.slick-slide.slick-active img')
      if (!imgEl) return

      const imgLeft = imgEl.getBoundingClientRect().left
      const imgRight = imgEl.getBoundingClientRect().right

      const slickLeftBtn = bannerEl.querySelector('.slick-prev')
      const slickRightBtn = bannerEl.querySelector('.slick-next')

      if (slickLeftBtn && slickRightBtn) {
        slickLeftBtn.style.left = `calc(${imgLeft}px -  2.5vw)`
        slickRightBtn.style.left = `calc(${imgRight}px -  2.5vw)`

        this.arrowPositionFlag = true
      }
    },
    reInit () {
      // skip set
      if (this.arrowPositionFlag) return

      setTimeout(() => {
        this.setArrowPosition()
      }, 0) // slide speed: 500
    }
  },

  watch: {
    'window.width': {
      handler () {
        this.arrowPositionFlag = false
        this.setArrowPosition()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap-banner {
  .main-slide {
    .banner-item {
      a {
        display: block;
        margin: auto;
      }

      img {
        border-radius: 0;
        width: 100%;
        height: 398px;
      }
    }

    @media #{$info-phone} {
      .banner-item {
        width: 70vw!important;

        img {
          height: auto !important;
          border-radius: 3px !important;
        }
      }
    }
  }
}
</style>
