<template>
  <main :class="{ 'pt-0': checkWebview(), 'padding-has-caution': isHasCaution }">

    <!-- slide banner -->
    <SlideBanner :list-data="bannerList" />

    <!-- content home page -->
    <div class="home-content container main-has-sidebar">
      <!-- sidebar on pc -->
      <sidebar class="pc" />

      <div class="main-content">
        <MidCategoryCarousel
          icon="ico_apply.svg"
          title="キャンペーン応募で貯める！"
          categoryId="520d7697-604e-11ef-9b8b-0a03d66a138d"
        />

        <MidCategoryCarousel
          icon="ico_look.svg"
          title="キャンペーンサイトを見て貯める！"
          categoryId="521b734d-604e-11ef-9b8b-0a03d66a138d"
          />

        <div class="mb-60">
          <picture>
            <source media="(min-width:781px)"
              srcset="../assets/images/bnr_camplist.png 1x, ../assets/images/bnr_camplist@2x.png 2x">
            <source media="(max-width:780px)" srcset="../assets/images/bnr_camplist-s.png">
            <img src="../assets/images/bnr_camplist.png" alt="毎日見て貯まる！キャンペーン一覧" class="switching cursor-pointer"
              @click="redirectToCampList()">
          </picture>
        </div>

        <MidCategoryCarousel
          icon="ico_shopping.svg"
          title="買い物をして貯める！"
          categoryId="5233c8f5-604e-11ef-9b8b-0a03d66a138d"
        />

        <div class="mb-60">
          <div class="title-wrapper">
            <h2>
              <span class="icon">
                <img src="../assets/images/icon/ico_quest.svg" alt="アンケートに答えて貯める！" />
              </span>
              アンケートに答えて貯める！
            </h2>
          </div>

          <picture>
            <source media="(min-width:781px)"
              srcset="../assets/images/bnr_quest.png 1x,../assets/images/bnr_quest@2x.png 2x">
            <source media="(max-width:780px)" srcset="../assets/images/bnr_quest-s.png">
            <img src="../assets/images/bnr_quest.png" alt="アンケートに答えて貯める！" class="switching cursor-pointer"
              @click="redirectToSurveyWallPage()">
          </picture>

          <div class="other-survey">
            <router-link :to="{
              name: 'CategoryList.Survey',
              params: { id: 'all' },
              query: { typeColor: 1 }
            }" :class="{ 'active': $route.name === 'CategoryList.Survey' }">そのほかのアンケートをみる</router-link>
          </div>
        </div>

        <MidCategoryCarousel
          icon="ico_light.svg"
          title="その他で貯める"
          categoryId="4a1320a4-57f5-4962-b0c7-f597bf00b18e"
        >
          <template v-slot:afterHeader>
            <div class="mb-15">
              <picture>
                <source media="(min-width:781px)"
                  srcset="../assets/images/bnr_app.png 1x,../assets/images/bnr_app@2x.png 2x">
                <source media="(max-width:780px)" srcset="../assets/images/bnr_app-s.png">
                <img src="../assets/images/bnr_app.png" alt="アプリで貯めよう！" class="switching cursor-pointer"
                  @click="handleOfferwall(SERVICE_OFFERWALL.SMAAD)">
              </picture>
            </div>

            <div class="mb-15">
              <picture>
                <source media="(min-width:781px)"
                  srcset="../assets/images/bnr_game.png 1x,../assets/images/bnr_game@2x.png 2x">
                <source media="(max-width:780px)" srcset="../assets/images/bnr_game-s.png">
                <img src="../assets/images/bnr_game.png" alt="ゲームで貯めよう！" class="switching cursor-pointer"
                  @click="handleOfferwall(SERVICE_OFFERWALL.MYCHIPS)">
              </picture>
            </div>
          </template>
        </MidCategoryCarousel>

      </div>
    </div>
    <!-- MODAL RECOMMEND ADV -->
    <Modal @close-modal="closeModal" class="modal-center modal-notify" :class="{ [typeColor]: !!typeColor }"
      v-if="flagModal && listRecommendModal.length">
      <div slot="body" class="responsive-height
          ">
        <div class="block-vertical" :class="{ [typeColor]: !!typeColor }">
          <ListItem :list="listRecommendModal" titleModal="スタッフいちおし!" hasBackground mode="vertical" isChangeBackground
            isInModal />
        </div>

        <button @click="closeModal" v-html="'閉じる'" class="btn-modal-recommend" />
      </div>
    </Modal>
  </main>
</template>

<script>
import Sidebar from '@/layouts/Sidebar'
import SlideBanner from '@/components/home/SlideBanner'
import Modal from '@/components/advertising-detail/Modal'
import ListItem from '@/components/ListItem.vue'
import MidCategoryCarousel from '@/components/home/MidCategoryCarousel.vue'

import Vue from 'vue'
import store from '@/store'
import { mapState, mapActions } from 'vuex'
import Common from '@/mixins/common.mixin'
import GMOResearch from '@/mixins/gmoResearch.mixin'
import { SERVICE_OFFERWALL } from '@/enum/options'
import { isMobile, isIOS } from 'mobile-device-detect'
import moment from 'moment'
import Cookie from 'js-cookie'

export default {
  name: 'Index',

  components: {
    SlideBanner,
    Sidebar,
    Modal,
    ListItem,
    MidCategoryCarousel
  },

  mixins: [Common, GMOResearch],
  data () {
    return {
      typeColor: 'cate-pink',
      flagModal: false,
      SERVICE_OFFERWALL,
      lpSrc: '//static.smaad.net/script/lp.js'
    }
  },

  beforeRouteLeave (to, from, next) {
    this.destroySmaScripts()
    next()
  },

  computed: {
    ...mapState('banner', ['bannerList']),
    ...mapState('auth', ['profile']),
    ...mapState('advertises', ['listRecommendModal']),

    isShowOfferWall () {
      const isOnNativeAppIOS = this.checkWebview() && isIOS && isMobile
      if (isOnNativeAppIOS) return false
      else return true
    },

    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }
  },

  // feature-list in top native
  created () {
    Promise.all([
      store.dispatch('banner/getList', { page: 1, perPage: 10 }),
      !Cookie.get('tracking_modal') && store.dispatch('advertises/getListRecommendModal')
    ])

    if (!this.checkWebview() && !Cookie.get('tracking_modal')) {
      this.flagModal = true
    }
  },

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
      document.addEventListener('VISIBLE_MODAL', (event) => {
        if (event.detail.toggle.toLowerCase() === 'true') {
          this.flagModal = true
        }
      })
    }
    this.initSmaScripts()
  },
  methods: {
    ...mapActions('advertisesMid', ['getListAdvertisesByMid', 'getListAdvertisesBySmall']),
    async initSmaScripts () {
      await Vue.loadScript(this.lpSrc, true)
    },
    async destroySmaScripts () {
      await Vue.unloadScript(this.lpSrc)
    },
    handleOfferwall (serviceId) {
      if (this.profile && Object.keys(this.profile).includes('id')) {
        if (serviceId === SERVICE_OFFERWALL.SMAAD) {
          this.redirectSmaAd()
        } else {
          this.redirectMyChips()
        }
      } else {
        this.openPageNative('Login')
      }
    },
    convertGenderToString (gender) {
      switch (gender) {
        case 1:
          return 'm'
        case 2:
          return 'f'
        default:
          return 'o'
      }
    },
    closeModal () {
      this.flagModal = false
      if (this.checkWebview()) {
        this.triggerEventApp('MODAL_CLOSED', {
          isTopModal: true
        })
      } else Cookie.set('tracking_modal', 1, { expires: 1, secure: true })
    },
    redirectSmaAd () {
      const url = process.env.VUE_APP_SMAAD_DOMAIN.replace('userId', this.profile.id)
      this.openUrl(url)
    },
    redirectMyChips () {
      const userAge = this.profile.birthday ? moment().year() - moment(this.profile.birthday).year() : ''
      const userGender = this.profile.gender ? this.convertGenderToString(this.profile.gender) : ''
      const matchValue = {
        userId: this.profile.id,
        userAge,
        userGender
      }
      const url = process.env.VUE_APP_MYCHIPS_DOMAIN.replace(/userId|userAge|userGender/gi, function (key) {
        return matchValue[key]
      })
      this.openUrl(url)
    },
    redirectToCampList () {
      const typeColor = '1'
      const level = 'point'
      const categoryId = 'point'

      if (this.checkWebview()) {
        const matchValue = {
          id: categoryId,
          '#color': typeColor,
          '#levelCategory': level
        }
        const url = process.env.VUE_APP_CATEGORY_DOMAIN.replace(
          /id|#color|#levelCategory/gi,
          function (key) {
            return matchValue[key]
          }
        )
        this.triggerEventApp('OPEN_WEBVIEW', { url: url })
      } else {
        this.$router.push({
          name: 'CategoryList.Category',
          params: { id: categoryId, title: '' },
          query: { typeColor, level }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$viewport: 780;

@function pv($num, $width: $viewport) {
  @return calc(1vw * $num * 100 / $width);
}

main {
  background: #ffffff;

  @media #{$info-phone} {
    padding-top: 41px;
    overflow-x: hidden;
  }

  &.padding-has-caution {
    @media #{$info-phone} {
      padding-top: 92px;
    }
  }

  .home-content {
    background: #ffffff;
    display: flex;
    align-items: flex-start;
    padding-top: 80px;
    padding-bottom: 96px;

    @media #{$info-phone} {
      padding-top: 18px;
      display: block;
      padding-bottom: 72px;
    }
  }

  .main-content {
    flex: 1;

    .mb-60 {
      margin-bottom: 60px;

      @media #{$info-phone} {
        margin-bottom: 8vw;
      }
    }

    .mb-15 {
      margin-bottom: 15px;

      @media #{$info-phone} {
        margin-bottom: 1.5vw;
      }
    }

    .title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto 20px;

      @media #{$info-phone} {
        margin: 0 auto 2vw;
      }

      h2 {
        font-family: A1 Gothic M, "A1 Gothic M", "Noto Sans JP", sans-serif;
        font-size: 28px;
        font-weight: 500;
        line-height: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @media #{$info-phone} {
          font-size: pv(28);
        }
      }

      .icon {
        width: 40px;
        text-align: center;

        img {
          max-height: 35px;
          margin-right: 20px;
        }

        @media #{$info-phone} {
          width: 5vw;

          img {
            max-height: 4vw;
            margin-right: 2vw;
          }
        }
      }

      span {
        font-size: 20px;
        font-weight: 400;
        line-height: 1;

        @media #{$info-phone} {
          font-size: pv(22);
        }

        .show-all {
          font-family: A1 Gothic M, "A1 Gothic M", "Noto Sans JP", sans-serif;
          text-decoration: underline;
          color: #282828;
          cursor: pointer;
        }
      }
    }

    .other-survey {
      margin-top: 20px;
      text-align: right;

      @media #{$info-phone} {
        margin-top: 1.5vw;
      }

      a {
        font-family: A1 Gothic M, "A1 Gothic M", "Noto Sans JP", sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 1;
        color: #282828;
        text-decoration: underline;

        @media #{$info-phone} {
          font-size: pv(22);
        }
      }
    }
  }
}
</style>
